export const Config = {
    api: {
        url: process.env.REACT_APP_API_URL || 'http://localhost:8080'
    },
    routerParamKeys: {
        bestPractice: 'bp',
        modelType: 'type',
        modelSubtype: 'subtype',
        categoryType: 'category'
    },
    links: {
        mcmaccxWebsite: 'https://mcmaccx.com'
    },
    appSettings: {
        // Control time between stat posts here
        PPPI_STAT_UPLOAD_TIMEOUT: 20000,
        DISABLE_PPPI_UPLOAD: process.env.REACT_APP_UPLOAD_DISABLE_PPPI_UPLOAD === "true"
    }
}

export const RouterLinks = {
    bestPractices: '/best-practices',
    onboarding: '/onboarding',
    login: '/login',
    register: '/register',
    account: '/account',
    professionals: '/professionals',
    about: '/about',
    howTo: '/how-to',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    videoOnboarding: '/video-onboarding'
}
