import React from 'react';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Avatar, Box, Button, Container, CssBaseline, FormHelperText, Link, TextField, Typography } from '@mui/material';
import { UserModel } from '../../models/UserModel';
import { RouterLinks } from '../../util/Config';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PopupModal from '../../components/ErrorModal/PopupModal';

interface Props {
    user: UserModel
}

enum Status {
    waiting,
    loading,
    success,
    error
}

function ResetPassword({ user }: Props) {

    const navigate = useNavigate();
    const [params] = useSearchParams();


    const [status, setStatus] = React.useState<Status>(Status.waiting);
    const [errorMsg, setErrorMsg] = React.useState<string>('');

    if (!params.has('code')) navigate(RouterLinks.forgotPassword);

    const resetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setStatus(Status.loading);
        const data = new FormData(event.currentTarget);
        const { error } = await user.resetPassword(params.get("code") || '', data.get('password') as string, data.get('passwordConfirmation') as string);
        if (error) {
            setStatus(Status.error);
            setErrorMsg(error)
        } else setStatus(Status.success)
    };

    return (
        <Container component="main">
            <PopupModal open={ status === Status.success } handleClose={ () => navigate(RouterLinks.login) }>
                <Typography component="h1" variant="h5" textAlign={ 'center' }>
                    Success!
                </Typography>
                <br/>
                <Typography variant="body1" textAlign={ 'center' }>
                    We've reset your password. You can now <Link onClick={ () => navigate(RouterLinks.login) } variant="body2"> login here </Link>
                </Typography>
            </PopupModal>
            <CssBaseline/>
            <Box
                sx={ {
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                } }
            >
                <Avatar sx={ { m: 1, bgcolor: 'secondary.main' } }>
                    <LockResetIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Enter your new password
                </Typography>
                <Box component="form" onSubmit={ resetPassword } sx={ { mt: 1 } }>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        disabled={ status === Status.loading || status === Status.success }
                        autoComplete="new-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="passwordConfirmation"
                        label="Confirmation"
                        type="password"
                        id="passwordConfirmation"
                        disabled={ status === Status.loading || status === Status.success }
                        autoComplete="passwordConfirmation"
                    />
                    { status === Status.error && <FormHelperText error={ true }>{ errorMsg }</FormHelperText> }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={ { mt: 3, mb: 2 } }
                    >
                        Reset Password
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default ResetPassword;
