export function titleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        function (txt: string) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export type ResponseObject = { [key: string]: any };

export class ConsistentColours {
    private inc = 0;
    private colors: string[] = [
        '#1092c2',
        '#2db270',
        '#e04343',
        '#f3782f',
        '#609d98',
        '#e11d7f',
        '#6268b2',
        '#a98240',
        '#7717b4',
        '#3058be',
    ];

    private mapping: { [key: string]: string } = {};

    get(i: string) {
        if (!this.mapping[i]) {
            while (this.inc >= this.colors.length) {
                this.colors.push(`#${ Math.floor(Math.random() * 16777215).toString(16) }`)
            }
            this.mapping[i] = this.colors[this.inc];
            this.inc++;
        }
        return this.mapping[i];
    }

}

export const LargeNumberFormatter = (number: number, decimals?: number) => {
    if (number >= 1000000000) {
        return parseFloat((number / 1000000000).toFixed(decimals || 1)).toString() + 'B';
    } else if (number >= 1000000) {
        return parseFloat((number / 1000000).toFixed(decimals || 1)).toString() + 'M';
    } else if (number >= 1000) {
        return parseFloat((number / 1000).toFixed(decimals || 1)).toString() + 'K';
    } else {
        return number.toFixed(2);
    }
}

export const ensureNumber = (v: number|string) => parseFloat(v as string);

export const DollarNumberFormatter = (value: number, decimals?: number) => {
    return value >= 0 ? `$${ LargeNumberFormatter(value, decimals) }` : `-$${ LargeNumberFormatter(value * -1, decimals) }`;
}
export const PercentageNumberFormatter = (value: number, digits?: number) => value >= 0 ? `${ value.toFixed(digits || 0) }%` : `0%`;

export type Nullable<C> = C | null;
export type Undefinable<C> = C | undefined;

export function intersection<T>(list1: T[], list2: T[]): T[] {
    const set1 = new Set(list1);
    return list2.filter(item => set1.has(item));
}
