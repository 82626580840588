import React, { useEffect } from 'react';
import styles from './BestPracticesViewer.module.scss';
import { Box, CssBaseline, IconButton, InputBase, MenuItem, Select, styled, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { HttpRequest } from '../../util/HttpRequest';
import { DollarNumberFormatter, Nullable, ResponseObject } from '../../util/Util';
import BestPractices, { BestPractice } from './bestPractice/BestPractices';
import BestPracticeCategories, { Category } from './BestPracticeCategories';
import BestPracticeChart from './bestPractice/BestPracticeChart';
import ContainerWithOptionalHeader from '../../components/ContainerWithOptionalHeader';
import Divider from '@mui/material/Divider';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import { useSearchParams } from 'react-router-dom';
import { Config } from '../../util/Config';
import { UserModel } from '../../models/UserModel';
import HTMLRender from '../../components/HTMLRender';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface Props {
    stickyNav: boolean,
    user: UserModel
}

interface ModelSubtype {
    id: number,
    title: string,
    iconUrl: string,
    tooltip: string | null
}

interface ModelType {
    id: number,
    title: string,
    subtypeIds: number[],
    iconUrl: string
}


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        border: 'none',
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        // marginBottom: '-5px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:hover': {
            borderColor: '#80bdff',
        },
    }
}));

function BestPracticesViewer({ stickyNav, user }: Props) {

    const [error, _setError] = React.useState(false);
    const [params, setParams] = useSearchParams();

    const [modelTypes, setModelTypes] = React.useState<ModelType[]>([]);
    const [activeModelType, _setActiveModelTypeIndex] = React.useState(-1);

    const [modelSubtypes, setModelSubtypes] = React.useState<ModelSubtype[]>([]);
    const [activeModelSubtype, _setActiveModelSubtypeIndex] = React.useState(-1);

    const [categories, setCategories] = React.useState<Category[]>([])
    const [activeCategory, _setActiveCategoryIndex] = React.useState(-1);

    const [bestPractices, setBestPractices] = React.useState<BestPractice[]>([]);
    const [activeTooltip, setActiveTooltip] = React.useState<Nullable<string>>(null);

    const [isolateChart, setIsolateChart] = React.useState(false);

    const setError = (v: boolean) => {
        console.error(new Error("Error"))
        _setError(v);
    }
    const setActiveCategory = (i: number, c: Category) => {
        params.set(Config.routerParamKeys.categoryType, c.name);
        setParams(params);
        _setActiveCategoryIndex(i);
        params.delete(Config.routerParamKeys.bestPractice)
        setParams(params)
    }

    const setActiveSubType = (i: number, p: ModelSubtype) => {
        params.set(Config.routerParamKeys.modelSubtype, p.title);
        setParams(params);
        _setActiveModelSubtypeIndex(i);
        params.delete(Config.routerParamKeys.bestPractice)
        setParams(params)
        setActiveTooltip(null)
    }

    const setActiveType = (i: number, p: ModelType) => {
        params.set(Config.routerParamKeys.modelType, p.title);
        setParams(params);
        _setActiveModelTypeIndex(i);
        params.delete(Config.routerParamKeys.bestPractice)
        params.delete(Config.routerParamKeys.modelSubtype)
        setParams(params)
        _setActiveModelSubtypeIndex(-1);
        setModelSubtypes([]);
        setActiveTooltip(null)
    }

    useEffect(() => {
        (async () => {
            try {
                const response = await HttpRequest.GET('model-types?populate[icon][fields][1]=url&fields[0]=name&populate[model_subtypes][fields][0]=id&sort[0]=order');
                if (!response.ok) throw new Error("Unknown error occurred");

                const r = await response.json();
                if (r.error) {
                    console.error(r.error)
                    return setError(true);
                }

                const types: ModelType[] = [];
                let index = -1;
                r.data.forEach((p: ResponseObject) => {
                    if (params.has(Config.routerParamKeys.modelSubtype) &&
                        params.get(Config.routerParamKeys.modelType) === p['attributes']['name']) {
                        index = types.length;
                    }
                    types.push({
                        id: p['id'],
                        title: p['attributes']['name'],
                        subtypeIds: p['attributes']['model_subtypes']['data'].map((i: any) => i['id']),
                        iconUrl: p['attributes']['icon']['data']['attributes']['url'],
                    });
                });

                setModelTypes(types);
                if (index >= 0) _setActiveModelTypeIndex(index)
                else if (types.length > 0) setActiveType(0, types[0]);
            } catch (e) {
                console.error(e);
            }
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            try {
                if (!modelTypes[activeModelType]) return;

                const idFilter = modelTypes[activeModelType].subtypeIds.map((id, i) => `filters[id][$in][${ i }]=${ id }`).join('&')
                const response = await HttpRequest.GET(`model-subtypes?populate[icon][fields][1]=url&fields[0]=name&fields[1]=tooltip&sort[0]=order&${ idFilter }`);
                if (!response.ok) throw new Error("Unknown error occurred");

                const r = await response.json();
                if (r.error) {
                    console.log(r.error)
                    return setError(true);
                }

                const subtypes: ModelSubtype[] = [];
                let index = -1;
                r.data.forEach((p: ResponseObject) => {
                    if (params.has(Config.routerParamKeys.modelSubtype) &&
                        params.get(Config.routerParamKeys.modelSubtype) === p['attributes']['name']) {
                        index = subtypes.length;
                    }
                    subtypes.push({
                        id: p['id'],
                        title: p['attributes']['name'],
                        iconUrl: p['attributes']['icon']['data']['attributes']['url'],
                        tooltip: p['attributes']['tooltip'] || null
                    });
                });

                setModelSubtypes(subtypes);
                if (index >= 0) _setActiveModelSubtypeIndex(index)
                else if (subtypes.length > 0) setActiveSubType(0, subtypes[0]);

            } catch (e) {
                console.error(e)
                setError(true);
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelTypes, activeModelType])


    useEffect(() => {
        if (activeModelSubtype < 0) return;
        const projectKey = modelSubtypes[activeModelSubtype].title;
        HttpRequest.GET(`categories?[fields][0]=name&filters[model_subtypes][name][$contains]=${ projectKey }&sort=order`)
            .then(r => r.json())
            .then(r => {
                if (r.error) {
                    return setError(true);
                }
                const arr: Category[] = [];
                let index = -1;
                r.data.forEach((p: ResponseObject) => {
                    if (params.has(Config.routerParamKeys.categoryType) &&
                        params.get(Config.routerParamKeys.categoryType) === p['attributes']['name']) {
                        index = arr.length;
                    }
                    arr.push({
                        name: p['attributes']['name'],
                    });
                });
                setCategories(arr);

                if (index >= 0) _setActiveCategoryIndex(index)
                else if (arr.length > 0) setActiveCategory(0, arr[0]);
                else _setActiveCategoryIndex(-1)
            })
            .catch(e => {
                console.error(e)
                setError(true);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelSubtypes, activeModelSubtype])

    return (
        <>
            <CssBaseline/>
            <ErrorModal open={ error } handleClose={ () => setError(false) }
                        title={ 'Uh oh, something went wrong' }
                        body={ `We couldn't process that request right now. Please wait a few minutes and try again` }
                        buttons={ [{ text: 'Ok', callback: () => setError(false) }] }/>


            <ContainerWithOptionalHeader title={ <span style={ { fontSize: '0.9em' } }>Planet People Profit Impact (P<sup>3</sup>I)</span> }>
                <Grid2 sx={ {
                    width: '100%',
                    height: '100%'
                } } container alignItems={ { xs: 'start', lg: 'initial' } }>
                    <Grid2 xs={ 12 } lg={ isolateChart ? 0 : 5 } xl={ isolateChart ? 0 : 4 } padding={ isolateChart ? {} : { xs: 1, lg: 2 } } margin={ 0 } justifyContent={ 'center' } alignItems={ 'center' } className={ styles.inputMenu }>
                        <IconButton onClick={ () => setIsolateChart(!isolateChart) } className={ styles.isolateButton } size={ 'large' }>
                            { isolateChart ? <ChevronRight/> : <ChevronLeft/> }
                        </IconButton>
                        <div className={ styles.bpMenu } style={ { height: isolateChart ? '0' : '' } }>
                            <Typography variant={ 'h6' } textAlign={ 'center' }>Model Type</Typography>
                            <Box className={ `${ styles.projectContainer } ${ stickyNav ? ' ' + styles.stickyNav : '' }` } marginBottom={ 1 }>
                                <Box sx={ { display: 'flex', justifyContent: 'center', minWidth: '100%', width: 'max-content' } }>
                                    <Grid2 container position={ 'relative' } alignItems={ 'center' } justifyContent={ 'center' }>
                                        <Grid2 textAlign={ 'center' } className={ styles.modelTypeSelector } title={ 'Change Model Type' }>
                                            <Select
                                                sx={ {} }
                                                size={ 'small' }
                                                value={ activeModelType }
                                                label="Model Type"
                                                onChange={ (e) => setActiveType(e.target.value as number, modelTypes[e.target.value as number]) }
                                                input={ <BootstrapInput/> }
                                            >

                                                { modelTypes.map((type, index) => <MenuItem value={ index } key={ type.id }>
                                                        <img height={ 25 } alt={ '' } src={ type.iconUrl }/>
                                                        <Typography variant={ 'body2' } className={ styles.modelTypeSelectorText }>{ type.title }</Typography>
                                                    </MenuItem>
                                                ) }
                                            </Select>
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 container alignItems={ 'center' } paddingRight={ 2 }>
                                        <div style={ { height: '80%', width: '1.5px', background: '#3f3f3f' } }></div>
                                    </Grid2>
                                    <Grid2 container flex={ 1 } justifyContent={ 'left' } spacing={ 3 } sx={ { width: 'max-content', margin: 0, padding: 0 } } alignItems={ 'center' } textAlign={ 'center' }>
                                        { modelSubtypes.map((p, i) =>
                                            <Grid2 key={ i } padding={ 1 } className={ `${ styles.project } ${ activeModelSubtype === i ? styles.activeProject : '' }` }
                                                   onClick={ () => setActiveSubType(i, p) } position={ 'relative' }>
                                                <img height={ 25 } alt={ '' } src={ p.iconUrl }/>
                                                <Typography variant={ 'body1' }>{ p.title }</Typography>
                                                { p.tooltip && <InfoIcon fontSize="small" className={ styles.tooltipIcon } onClick={ e => {
                                                    e.stopPropagation()
                                                    setActiveSubType(i, p);
                                                    setActiveTooltip(p.tooltip);
                                                } }/> }
                                            </Grid2>) }
                                    </Grid2>
                                </Box>
                            </Box>
                            <Divider/>
                            {
                                categories.length === 0 && <Box paddingTop={2} textAlign={ 'center' }>
                                <Box>
                                  <ErrorOutlineIcon fontSize={ "large" }/>
                                </Box>
                                No data right now. Please check back later!
                              </Box>
                            }
                            { categories.length > 0 && <>
                            <Typography variant={ 'h6' } textAlign={ 'center' } sx={ { paddingTop: 2 } }>Best Practice Type</Typography>
                            <Box sx={ { display: 'flex', justifyContent: 'center', marginTop: 1, marginBottom: 3 } }>
                                <Box className={ `${ styles.categoryContainer } customScroll` }>
                                    <BestPracticeCategories activeCategory={ activeCategory } setActiveCategory={ setActiveCategory } categories={ categories }/>
                                </Box>
                            </Box>
                            <Divider/> </> }
                            { activeModelSubtype >= 0 && activeModelType >= 0 && activeCategory >= 0 &&
                              <BestPractices bestPractices={ bestPractices } setBestPractices={ setBestPractices }
                                             setError={ setError } categoryKey={ categories[activeCategory].name }
                                             modelSubtypeId={ modelSubtypes[activeModelSubtype].id }
                                             modelTypeId={ modelTypes[activeModelType].id }
                              />
                            }
                        </div>
                    </Grid2>
                    { activeModelSubtype >= 0 && activeCategory >= 0 && <>
                      <Grid2 xs={ 12 } lg={ isolateChart ? 12 : 7 } xl={ isolateChart ? 12 : 8 } position={ 'relative' }>
                        <BestPracticeChart dollarNumberFormatter={ DollarNumberFormatter } bestPractices={ bestPractices }
                                           activeProject={ activeModelSubtype } activeCategory={ activeCategory } user={ user }/>
                          { activeTooltip && <div className={ styles.tooltipModal }>
                            <div className={ styles.content }>
                              <div className={ styles.closeButton } onClick={ () => setActiveTooltip(null) }>
                                <CancelIcon/>
                              </div>
                              <HTMLRender content={ activeTooltip }/>
                            </div>
                          </div> }
                      </Grid2>
                    </> }
                </Grid2>
            </ContainerWithOptionalHeader>
        </>
    );
}

export default BestPracticesViewer;
