import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import styles from './BestPracticesViewer.module.scss';
import { Button } from '@mui/material';

interface Props {
    setActiveCategory: (i: number, c: Category) => void;
    activeCategory: number;
    categories: Category[];
}

export interface Category {
    name: string;
}

export default function BestPracticeCategories({ categories, setActiveCategory, activeCategory }: Props) {

    return <Grid2 container spacing={ 3 } sx={ { width: 'max-content', margin: 0, padding: 0 } } textAlign={ 'center' }>
        { categories.map((c, i) => <Grid2 key={ i } className={ styles.project } padding={ 1 }>
            <Button onClick={ () => setActiveCategory(i, c) } variant={ i === activeCategory ? 'contained' : 'outlined' }>{ c.name }</Button>
        </Grid2>) }
    </Grid2>
}
