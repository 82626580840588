import React from 'react';
import styles from './Account.module.scss';
import { Button, CssBaseline, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { UserModel } from '../../models/UserModel';
import Grid2 from '@mui/material/Unstable_Grid2';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import ContainerWithOptionalHeader from '../../components/ContainerWithOptionalHeader';
import PopupModal from '../../components/ErrorModal/PopupModal';
import { HttpRequest } from '../../util/HttpRequest';

interface Props {
    user: UserModel;
}

export interface Role {
    id: string,
    name: string
}

function Account({ user }: Props) {

    const [error, setError] = React.useState(false);
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    const [roles, setRoles] = React.useState<Role[]>([])
    React.useEffect(() => {
        HttpRequest.GET('user-roles').then(async (resp) => {
            if (!resp.ok) alert('Something went very wrong. Please try again later.')
            const data = await resp.json();
            setRoles(data['data'].map((d: any) => ({ id: d['id'], name: d['attributes']['name'] })));
        })
    }, [])
    const [newRole, setNewRole] = React.useState(-1)

    const deleteAccount = async () => {
        const { error } = await user.deleteAccount();
        if (!error) {
            user.logout();
        } else {
            setError(true);
        }
    }

    return (
        <>
            <CssBaseline/>
            <ErrorModal open={ confirmDelete } handleClose={ () => setConfirmDelete(false) }
                        title={ 'Are you sure?' }
                        body={ `This will delete all of your account data. It cannot be undone.` }
                        buttons={ [
                            { text: 'Cancel', variant: 'outlined', callback: () => setConfirmDelete(false) },
                            { text: 'Delete', callback: deleteAccount }
                        ] }/>
            <ErrorModal open={ error } handleClose={ () => setError(false) }
                        title={ 'Uh oh, something went wrong' }
                        body={ `We couldn't process that request right now. Please wait a few minutes and try again` }
                        buttons={ [{ text: 'Ok', callback: () => setError(false) }] }/>

            <PopupModal open={ !user.userRole } handleClose={ () => {
            } }>
                <Grid2 container justifyContent={ 'center' } alignItems={ 'center' } spacing={ 2 }>
                    <Grid2 xs={ 12 } textAlign={ 'center' }><Typography variant={ 'h5' }>Please select your role to continue</Typography></Grid2>
                    <Grid2 textAlign={ 'center' }>
                        <FormControl>
                            <Select
                                value={ newRole }
                                onChange={ (e) => setNewRole(e.target.value as number) }
                            >
                                { roles.map(r => <MenuItem value={ r.id }>{ r.name }</MenuItem>) }
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2>
                        <Button disabled={ newRole === -1 } type={ 'submit' } size={ 'large' } variant={ 'contained' } onClick={ () => user.setRole(newRole) }>Save</Button>
                    </Grid2>
                </Grid2>
            </PopupModal>

            <ContainerWithOptionalHeader title={ "Account" }>
                <>
                    <Grid2 container
                           direction={ 'column' }
                           justifyContent="space-between"
                           className={ styles.gridContainer }
                           alignItems="flex-start">
                        <Grid2 xs={ 12 } textAlign={ 'center' } paddingTop={ '15vh' }>
                            <Typography variant={ 'h5' }>{ user.username }</Typography>
                            <Typography variant={ 'h5' }>{ user.email }</Typography>
                            <Typography variant={ 'h5' }>{ user.userRole }</Typography>
                        </Grid2>
                        <Grid2 xs={ 12 } textAlign={ 'center' }>
                            <Button fullWidth className={ styles.deleteAccount } onClick={ () => setConfirmDelete(true) }>Delete Account</Button>
                        </Grid2>
                    </Grid2>
                </>
            </ContainerWithOptionalHeader>
        </>
    );
}

export default Account;
