import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Button, Container, CssBaseline, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { UserModel } from '../../models/UserModel';
import { RouterLinks } from '../../util/Config';
import { useNavigate } from 'react-router-dom';
import { HttpRequest } from '../../util/HttpRequest';
import { Role } from '../account/Account';

interface Props {
    user: UserModel
}

function Register({ user }: Props) {

    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [roles, setRoles] = React.useState<Role[]>([])
    React.useEffect(() => {
        HttpRequest.GET('user-roles').then(async (resp) => {
            if (!resp.ok) alert('Something went very wrong. Please try again later.')
            const data = await resp.json();
            setRoles(data['data'].map((d: any) => ({ id: d['id'], name: d['attributes']['name'] })));
        })
    }, [])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        const data = new FormData(event.currentTarget);
        const { error } = await user.register(data.get('name') as string, data.get('email') as string, data.get('password') as string, parseInt(data.get('role') as string));
        if (error) {
            setError(error);
            setLoading(false);
        } else navigate('/home');
    };

    return (
        <Container component="main">
            <CssBaseline/>
            <Box
                sx={ {
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                } }
            >
                <Avatar sx={ { m: 1, bgcolor: 'secondary.main' } }>
                    <PersonIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    New Account
                </Typography>
                <Box component="form" onSubmit={ handleSubmit } noValidate sx={ { mt: 1 } }>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Full Name"
                        name="name"
                        autoComplete="name"
                        disabled={ loading }
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        disabled={ loading }
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        disabled={ loading }
                        autoComplete="current-password"
                    />
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select name="role" id="role" fullWidth label="Role">
                        { roles.map(r => <MenuItem value={ r.id }>{ r.name }</MenuItem>) }
                    </Select>
                    { !loading && error && <FormHelperText error={ !!error }>{ error }</FormHelperText> }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={ { mt: 3, mb: 2 } }
                    >
                        Create Account
                    </Button>
                    <Grid container>
                        <Grid item xs={ 12 } textAlign={ 'center' }>
                            <Link onClick={ () => navigate(RouterLinks.login) } variant="body2">
                                { "Already have an account? Login here" }
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default Register;
