import React, { useEffect } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, CssBaseline, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { HttpRequest } from '../../util/HttpRequest';
import ErrorMessage from '../../components/ErrorMessage';
import Grid2 from '@mui/material/Unstable_Grid2';
import { titleCase } from '../../util/Util';
import { useNavigate } from 'react-router-dom';
import { RouterLinks } from '../../util/Config';

interface Page {
    title: string,
    description: string,
    imageUrl: string;
}


function OnBoarding() {

    const navigate = useNavigate();

    const [error, setError] = React.useState(false);
    const [pages, setPages] = React.useState<Page[]>([]);
    const [activeStep, setActiveStep] = React.useState(0);

    // Load onboarding pages
    useEffect(() => {
        HttpRequest.GET('on-boardings?sort[0]=id&populate=image')
            .then(async r => ({ ok: r.ok, status: r.status, result: await r.json() }))
            .then(r => {
                if (!r.ok || !r.result.data) setError(true);
                const pages: Page[] = [];
                r.result.data.forEach((p: any) => {
                    const { attributes } = p;
                    if (attributes['image'].data.length === 0) return;
                    pages.push({
                        title: attributes['title'],
                        description: attributes['description'],
                        imageUrl: attributes['image']['data'][0]['attributes']['url']
                    })
                })
                setPages(pages);
            })
    }, []);

    return (
        <>
            <CssBaseline/>
            <ErrorMessage show={ error }/>
            { !error &&
              <Box
                sx={ {
                    width: '100%',
                    left: 0,
                    top: 0,
                    position: 'absolute',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    boxSizing: 'border-box'
                } }
              >
                <Stepper orientation={ 'horizontal' } alternativeLabel activeStep={ activeStep }>
                    { pages.map((p, i) => <Step key={ i }>
                        <StepLabel onClick={ () => setActiveStep(i) }>{ titleCase(p.title) }</StepLabel>
                    </Step>) }
                </Stepper>
                <Box sx={ { flex: 1, overflow: 'auto' } }>
                  <Box sx={ { minHeight: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    <Card sx={ { width: '90%', maxWidth: '500px', marginTop: 2, marginBottom: 2 } }>
                      <CardMedia
                        component={ 'img' }
                        sx={ { objectFit: 'contain' } }
                        image={ pages[activeStep]?.imageUrl }/>
                      <CardContent>
                        <Typography>{ pages[activeStep]?.description }</Typography>
                      </CardContent>
                      <CardActions>
                        <Grid2 container width={ '100%' } justifyContent={ 'space-between' }>
                          <Grid2>
                            <Button variant={'outlined'} sx={{marginRight: 2}} onClick={ () => setActiveStep(activeStep - 1) }>Previous</Button>
                              { activeStep < pages.length - 1 ?
                                  <Button variant={'outlined'} onClick={ () => setActiveStep(activeStep + 1) }>Next</Button> :
                                  <Button variant={'contained'} onClick={ () => navigate(RouterLinks.login) }>Login</Button>
                              }
                          </Grid2>
                          <Grid2>
                            <Button color={ 'error' } variant={'outlined'} onClick={ () => navigate(RouterLinks.login) }>Skip</Button>
                          </Grid2>
                        </Grid2>
                      </CardActions>
                    </Card>
                  </Box>
                </Box>
              </Box>
            }
        </>
    );
}

export default OnBoarding;
