import React, { ReactElement } from 'react';
import styles from './VideoPlayerPage.module.scss';
import { CssBaseline } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import ContainerWithOptionalHeader from '../../components/ContainerWithOptionalHeader';

export function VideoPlayerPage({ videoUrl, title }: { videoUrl: string, title?: string | ReactElement}) {

    const [error, setError] = React.useState(false);

    return (
        <>
            <CssBaseline/>
            <ErrorModal open={ error } handleClose={ () => setError(false) }
                        title={ 'Uh oh, something went wrong' }
                        body={ `We couldn't process that request right now. Please wait a few minutes and try again` }
                        buttons={ [{ text: 'Ok', callback: () => setError(false) }] }/>
            <ContainerWithOptionalHeader title={ title }>
                <>
                    <Grid2 container
                           justifyContent="center"
                           height={ '90%' }
                           alignItems="center">
                        <Grid2 xs={ 12 } textAlign={ 'center' }>
                            { videoUrl &&
                              <video controls className={ styles.video } key={videoUrl}>
                                <source src={ videoUrl }/>
                              </video> }
                        </Grid2>
                    </Grid2>
                </>
            </ContainerWithOptionalHeader>
        </>
    );
}

