import { HttpRequest } from '../util/HttpRequest';
import { makeAutoObservable, runInAction } from 'mobx';
import { Config } from '../util/Config';

interface User {
    email: string
    username: string
    jwt: string
    id: number,
    userRole: {
        id?: number,
        name?: string
    }
}

export class UserModel {

    static STORAGE_KEY = 'USER_JSON';
    private userData: User = {
        email: '',
        username: '',
        jwt: '',
        id: -1,
        userRole: {}
    }

    authenticated: boolean = false;

    constructor() {
        makeAutoObservable(this);
        this.load();
    }

    get jwt() {
        return this.userData.jwt;
    }

    get id() {
        return this.userData.id;
    }

    get username() {
        return this.userData.username;
    }

    get email() {
        return this.userData.email;
    }

    get userRole() {
        return this.userData.userRole.name;
    }


    isAuthenticated() {
        return this.authenticated && this.userData.email !== '' && this.userData.jwt !== '';
    }

    private processUserDataResponse(result: { user: any, jwt: any }, triggerUpdate: boolean = true) {
        runInAction(() => {
            this.userData = {
                email: result['user']['email'],
                username: result['user']['username'],
                id: result['user']['id'],
                jwt: result['jwt'],
                userRole: result['user']['user_role'] ? {
                    id: result['user']['user_role']['id'],
                    name: result['user']['user_role']['name']
                } : {}
            }

            if (triggerUpdate) {
                this.authenticated = true;
                this.save();
            }
        })
    }

    async register(name: string, email: string, password: string, userRoleId: number): Promise<{ error?: string }> {
        const response = await HttpRequest.POST('/auth/local/register', {
            username: name,
            email,
            password,
        });
        if (!response.ok || !response.result) return { error: response.result.error?.message || 'Something went wrong. Please try again later.' }
        this.processUserDataResponse(response.result, false);
        return this.setRole(userRoleId)
    }

    async login(email: string, password: string): Promise<{ error?: string }> {
        const loginResponse = await HttpRequest.POST('/auth/local', {
            identifier: email,
            password: password,
        });
        if (!loginResponse.ok || !loginResponse.result) return { error: loginResponse.result.error?.message || 'Something went wrong. Please try again later.' }

        await this.loadProfile(loginResponse.result['jwt'])
        return {};
    }

    async loadProfile(jwt: string): Promise<{ error?: string }> {
        const profileResponse = await HttpRequest.GET('users/me?populate=*', this.jwtAuthHeader(jwt));
        if (profileResponse.status === 401) {
            this.logout();
            return {};
        }
        if (!profileResponse.ok) {
            return { error: 'Something went wrong. Please try again later.' }
        }
        this.processUserDataResponse({ jwt, user: await profileResponse.json() });
        return {};
    }

    save() {
        localStorage.setItem(UserModel.STORAGE_KEY, JSON.stringify(this.userData));
    }

    load() {
        const data = localStorage.getItem(UserModel.STORAGE_KEY);
        if (!data) return;
        this.userData = JSON.parse(data);
        this.authenticated = true;

        this.loadProfile(this.jwt);
    }

    logout = () => {
        this.userData = {
            email: '',
            username: '',
            jwt: '',
            id: -1,
            userRole: {}
        }
        this.authenticated = false;
        localStorage.removeItem(UserModel.STORAGE_KEY)
    }

    jwtAuthHeader(jwt: string, headers?: Headers) {
        if (!headers) headers = new Headers();
        headers.set('Authorization', `Bearer ${ jwt }`);
        return headers;
    }

    async deleteAccount(): Promise<{ error?: string }> {
        const response = await HttpRequest.DELETE(`/users/${ this.userData.id }`, this.jwtAuthHeader(this.jwt));
        if (!response.ok || !response.result) return { error: response.result.error?.message || 'Something went wrong. Please try again later.' }
        runInAction(() => {
            this.logout();
        })
        return {};
    }

    async setRole(newRole: number): Promise<{ error?: string }> {
        const response = await HttpRequest.PUT(`/users/${ this.userData.id }`, {
            user_role: {
                set: [newRole]
            }
        }, this.jwtAuthHeader(this.jwt));
        if (!response.ok || !response.result) return { error: response.result.error?.message || 'Something went wrong. Please try again later.' }
        return await this.loadProfile(this.jwt);
    }

    async requestPasswordReset(email: string): Promise<{ error?: string }> {
        const response = await HttpRequest.POST('/auth/forgot-password', { email })
        if (!response.ok || !response.result) return { error: response.result.error?.message || 'Something went wrong. Please try again later.' }
        return {};
    }

    async resetPassword(code: string, password: string, passwordConfirmation: string): Promise<{ error?: string }> {
        const response = await HttpRequest.POST('/auth/reset-password', { code, password, passwordConfirmation })
        if (!response.ok || !response.result) return { error: response.result.error?.message || 'Something went wrong. Please try again later.' }
        return {};
    }

    timeoutId: ReturnType<typeof setTimeout> | undefined;

    async postPPPIStat(payload: PPPIStat, error?: (msg: String) => {}): Promise<void> {
        if (Config.appSettings.DISABLE_PPPI_UPLOAD) return console.warn("PPPI Stat uploading disabled.");
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(async () => {
            const response = await HttpRequest.POST('/pppi-stats', {
                data: {
                    user: {
                        set: [this.id]
                    },
                    payload,
                }
            }, this.jwtAuthHeader(this.jwt))
            if (!!error && (!response.ok || !response.result)) error(response.result.error?.message || 'Something went wrong. Please try again later.');
        }, Config.appSettings.PPPI_STAT_UPLOAD_TIMEOUT);
    }
}

// This interface is linked to the strapi /download-stats api. Any changes here need to be updated there.
interface PPPIStat {
    value: number,
    bpSelected: string[]
}
