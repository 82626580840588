import React from 'react'
import { Navigate, useLocation, Location } from "react-router-dom"
import { UserModel } from '../models/UserModel';
import { Nullable } from '../util/Util';
import { RouterLinks } from '../util/Config';

interface Props {
    user: UserModel,
    children: any,
    authUrl: string,
    redirectLocation: Nullable<Location>;
    setRedirectLocation: (s: Nullable<Location>) => void;
}

const ProtectedRoute = ({ user, children, authUrl, redirectLocation, setRedirectLocation }: Props) => {
    let location = useLocation();

    if (!user.isAuthenticated()) {
        setRedirectLocation(location);
        return <Navigate to={ authUrl } state={ { from: location } } replace/>
    } else if (!user.userRole && location.pathname !== RouterLinks.account) {
        return <Navigate to={ RouterLinks.account } state={ { from: location } } replace/>
    } else if (!!redirectLocation) {
        const redirect = redirectLocation;
        setRedirectLocation(null);
        return <Navigate to={ redirect } state={ { from: location } } replace/>
    }
    return children
};

export default ProtectedRoute;
