import { Config } from './Config';

export const HttpRequest = {
    GET: (url: string, headers?: Headers) => fetch(`${ Config.api.url }/${ url }`, {
        method: 'GET',
        headers
    }),
    POST: (url: string, body: object, headers?: Headers) => fetch(`${ Config.api.url }${ url }`, {
        method: 'POST',
        headers: (() => {
            if (!headers) headers = new Headers();
            headers.set('Content-type', 'application/json')
            return headers;
        })(),
        body: JSON.stringify(body),
    }).then(async r => ({ ok: r.ok, status: r.status, result: await r.json() })),
    PUT: (url: string, body: object, headers?: Headers) => fetch(`${ Config.api.url }${ url }`, {
        method: 'PUT',
        headers: (() => {
            if (!headers) headers = new Headers();
            headers.set('Content-type', 'application/json')
            return headers;
        })(),
        body: JSON.stringify(body),
    }).then(async r => ({ ok: r.ok, status: r.status, result: await r.json() })),
    DELETE: (url: string, headers?: Headers) => fetch(`${ Config.api.url }${ url }`, {
        method: 'DELETE',
        headers
    }).then(async r => ({ ok: r.ok, status: r.status, result: await r.json() }))
}
