import { Typography } from '@mui/material';
import * as React from 'react';
import { Error } from '@mui/icons-material';

interface Props {
    show: boolean,
    component?: React.ElementType,
    icon?: JSX.Element
}

export default function ErrorMessage({ show, component, icon }: Props) {
    return show ?
        <div style={ { textAlign: 'center' } }>
            { icon || <Error/> }
            <Typography component={ component || 'h3' } variant={ 'h5' }>Something went wrong. Please try accessing this again later.</Typography>
        </div> : <></>;
}
