import * as React from 'react';
import { Box, Modal } from '@mui/material';
import styles from './PopupModal.module.scss'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    open: boolean,
    handleClose: () => void;
}

export default function PopupModal({ open, handleClose, children }: Props) {

    return <Modal
        open={ open }
        onClose={ handleClose }>
        <Box className={ styles.modal }>
            { children }
        </Box>
    </Modal>

}
