import * as React from 'react';
import { Button, ButtonProps, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import PopupModal from './PopupModal';

interface Props {
    open: boolean,
    handleClose: () => void;
    title: string;
    body: string | JSX.Element;
    buttons: {
        text: string,
        callback: () => void,
        className?: string;
        variant?: ButtonProps['variant']
    }[],
}

export default function ErrorModal({ open, handleClose, title, body, buttons }: Props) {

    return <PopupModal
        open={ open }
        handleClose={ handleClose }>
        <Grid2 container spacing={ 2 }>
            <Grid2 xs={ 12 } textAlign={ 'center' }>
                <Typography variant={ 'h6' }>{ title }</Typography>
            </Grid2>
            <Grid2  textAlign={ 'center' } xs={ 12 }>
                { typeof body === "string" ? <Typography variant={ 'body1' }>{ body }</Typography> : body }
            </Grid2>
            <Grid2 container spacing={ 2 } xs={ 12 } textAlign={ 'center' } justifyContent={ 'center' }>
                { buttons.map((b, i) =>
                    <Grid2 key={ i }>
                        <Button variant={ b.variant || 'contained' } onClick={ b.callback } className={ b.className }>{ b.text }</Button>
                    </Grid2>)
                }
            </Grid2>
        </Grid2>
    </PopupModal>

}
