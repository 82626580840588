import React from 'react';
import DOMPurify from 'dompurify'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    content: string;
}

function HTMLRender({ content, ...props }: Props) {

    return (
        <>
            <div { ...props } dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(content) } }></div>
        </>);
}

export default HTMLRender;
