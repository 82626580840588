import { styled, ToggleButtonGroup } from '@mui/material';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    '& .MuiToggleButtonGroup-grouped': {
        color: '#176423',
        border: '1px solid rgba(23, 100, 35, 0.5)',
        '&.Mui-selected': {
            color: 'white',
            backgroundColor: '#176423 !important'
        }
    },
}));
