import React, { useEffect, useMemo } from 'react';
import styles from './BestPractices.module.scss';
import { HttpRequest } from '../../../util/HttpRequest';
import { ResponseObject, titleCase } from '../../../util/Util';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Box, Button, Checkbox, Slider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PopupModal from '../../../components/ErrorModal/PopupModal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HTMLRender from '../../../components/HTMLRender';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSearchParams } from 'react-router-dom';
import { Config } from '../../../util/Config';
import Divider from '@mui/material/Divider';
import BenchmarkAccordion, { BenchmarkSection } from './BenchmarkAccordion';
import { BP_DISPLAY_COLUMNS } from './BestPracticeChart';

interface Props {
    modelSubtypeId: number,
    modelTypeId: number,
    categoryKey: string,
    setError: (b: boolean) => void;
    bestPractices: BestPractice[],
    setBestPractices: (n: BestPractice[]) => void;
}

export interface BestPractice {
    name: string;
    benchmarkHeader: string; // Static top section
    benchmarks: BenchmarkSection[]; // Accordion subsections
    enabled: boolean;
    table_data: {
        better: {
            [key: string]: number
        },
        best: {
            [key: string]: number
        }
    }
    options: {
        better?: string,
        best: string
    },
    sliderWeight: number
}

export interface Remark {
    name: string,
    better: number,
    best: number
}


function BestPractices({ modelSubtypeId, modelTypeId, categoryKey, setError, bestPractices, setBestPractices }: Props) {

    const [params, setParams] = useSearchParams();
    const [remarkOrderKey, setRemarkOrderKey] = React.useState<string>('');

    const bpsEnabled = useMemo(() => {
        if (!params.has(Config.routerParamKeys.bestPractice)) return new Set<string>();
        return params.getAll(Config.routerParamKeys.bestPractice).reduce((p, c) => p.add(c), new Set<string>());
    }, [params]);

    const [detailsModal, setDetailsModel] = React.useState<null | {
        title: string,
        topSection: string
        subSections: BenchmarkSection[]
    }>(null)

    useEffect(() => {
        HttpRequest.GET(`best-practices?populate=benchmarks&filters[model_type][id][$eq]=${ modelTypeId }&filters[model_subtype][id][$eq]=${ modelSubtypeId }&filters[category][name][$eq]=${ categoryKey }`)
            .then(r => r.json())
            .then(r => {
                if (r.error) return setError(true);
                const arr: BestPractice[] = [];

                r.data.forEach((p: ResponseObject) => {
                    arr.push({
                        name: p['attributes']['name'],
                        benchmarkHeader: p['attributes']['benchmark'],
                        benchmarks: p['attributes']['benchmarks'],
                        enabled: bpsEnabled.has(p['attributes']['name']),
                        options: {
                            ...('best' in (p['attributes']['table_data'] || {}) ? { better: 'Better' } : {}),
                            best: 'Best'
                        },
                        table_data: p['attributes']['table_data'],
                        sliderWeight: 0
                    });
                });
                setBestPractices(arr);
            })
            .catch(e => {
                console.error(e);
                setError(true);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelSubtypeId, categoryKey]);

    const toggleBp = (bp: BestPractice, i: number, checked: boolean) => {
        updateBp(i, 'enabled', checked);
        if (checked) bpsEnabled.add(bp.name);
        else bpsEnabled.delete(bp.name)
        params.delete(Config.routerParamKeys.bestPractice);
        bpsEnabled.forEach(k => params.append(Config.routerParamKeys.bestPractice, k))
        setParams(params);
    }

    const updateBp = (i: number, key: keyof BestPractice, val: any) => {
        setBestPractices([
            ...bestPractices.slice(0, i),
            {
                ...bestPractices[i],
                [key]: val,
            },
            ...bestPractices.slice(i + 1)
        ])
    }


    return (
        <>

            <PopupModal open={ !!detailsModal } handleClose={ () => setDetailsModel(null) }>
                <Button sx={ { position: 'absolute', top: 5, right: 5 } } onClick={ () => setDetailsModel(null) }><HighlightOffIcon fontSize={ 'large' }/></Button>

                <HTMLRender content={ detailsModal?.topSection || '' }/>
                <BenchmarkAccordion sections={ detailsModal ? detailsModal.subSections : [] }/>
            </PopupModal>

            <Grid2 container justifyContent={ 'center' } padding={ 2 } spacing={ 1 } paddingBottom={ 3 }>

                <Grid2 xs={ 12 }><Typography variant={ 'h6' } textAlign={ 'center' }>Prioritize Impact Type</Typography></Grid2>
                <Grid2 container spacing={ 1 } justifyContent={ 'center' }>
                    { BP_DISPLAY_COLUMNS.map(k => k.hidden ? <React.Fragment key={ k.key }></React.Fragment> : <Grid2 key={ k.key }>
                        <Button variant={ remarkOrderKey === k.key ? 'contained' : 'outlined' }
                                onClick={ () => setRemarkOrderKey(k.key) }>{ titleCase(k.name) }</Button>
                    </Grid2>) }
                </Grid2>
            </Grid2>
            <Divider/>

            <Grid2 container justifyContent={ 'center' } alignItems={ 'center' } spacing={ 1 } paddingTop={ 2 }>
                <Grid2><Typography variant={ 'h6' } textAlign={ 'center' }>Best Practices</Typography></Grid2>
            </Grid2>

            <List className={ styles.bpList } sx={ { paddingTop: 0 } }>
                { bestPractices.sort((a, b) => {
                    const remarkA = a.table_data.best[remarkOrderKey] ?? null;
                    const remarkB = b.table_data.best[remarkOrderKey] ?? null;
                    if (remarkA !== null && remarkB != null) return remarkB - remarkA
                    if (remarkA !== null) return -1;
                    if (remarkB  !== null) return 1;
                    return 0;
                }).map((bp, i) =>
                    <ListItem className={ styles.listItem } sx={ { paddingLeft: 0 } } key={ i }>
                        <Grid2 sx={ { padding: 0, margin: 0, width: '100%' } } container alignItems={ 'space-between' }>
                            <Grid2 alignItems={ 'center' } display={ 'flex' } xs={ 12 }>
                                <Checkbox checked={ bp.enabled } onChange={ event => toggleBp(bp, i, event.target.checked) }/>
                                <Typography variant={ 'body1' }>{ bp.name }</Typography>
                                { !!bp.benchmarks &&
                                <Button sx={ { minWidth: 0 } } onClick={ () => setDetailsModel({
                                    title: bp.name,
                                    topSection: bp.benchmarkHeader,
                                    subSections: bp.benchmarks,
                                }) } disableRipple={ true }><HelpOutlineIcon/></Button>}
                            </Grid2>
                            <Grid2 container spacing={ 0 } justifyContent={ 'center' } alignItems={ 'center' } xs={ 12 }>
                                { bp.enabled && bp.options['better'] && <>
                                  <Grid2 marginRight={ 3 }>
                                    <Button size={ 'small' } variant={ bp.sliderWeight <= 0 ? 'contained' : 'outlined' }
                                            onClick={ () => updateBp(i, 'sliderWeight', 0) } fullWidth>{ bp.options['better'] }</Button>
                                  </Grid2>
                                  <Grid2 xs={ true }>
                                    <Slider aria-label="Volume" min={ 0 } max={ 100 } value={ bp.sliderWeight } onChange={ (e, value) => {
                                        updateBp(i, 'sliderWeight', value)
                                    } }/>
                                  </Grid2>
                                  <Grid2 marginLeft={ 3 }>
                                    <Button size={ 'small' } variant={ bp.sliderWeight >= 100 ? 'contained' : 'outlined' }
                                            onClick={ () => updateBp(i, 'sliderWeight', 100) } fullWidth>{ bp.options['best'] }</Button>
                                  </Grid2>
                                </> }
                            </Grid2>
                        </Grid2>
                    </ListItem>
                ) }
            </List>

            { bestPractices.length === 0 && <Box textAlign={ 'center' }>
              <Box>
                <ErrorOutlineIcon fontSize={ "large" }/>
              </Box>
              No data right now. Please check back later!
            </Box> }
        </>
    );
}

export default BestPractices;
