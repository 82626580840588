import React from 'react';
import { Nullable } from '../../../util/Util';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HTMLRender from '../../../components/HTMLRender';

interface Props {
    sections: BenchmarkSection[];
}


export interface BenchmarkSection {
    id: number;
    title: string;
    content: string;
}

export default function BenchmarkAccordion({ sections }: Props) {

    const [expanded, setExpanded] = React.useState<Nullable<number>>(null);

    return <div>
        { sections.map((s, i) =>
                <Accordion key={s.id} expanded={ expanded === i } onChange={ (e, expanded) => setExpanded(expanded ? i : null) }>
                    <AccordionSummary expandIcon={ <ExpandMoreIcon/> } >
                        { s.title }
                    </AccordionSummary>
                    <AccordionDetails>
                        <HTMLRender content={ s.content }/>
                    </AccordionDetails>
                </Accordion>
        ) }
    </div>
}
