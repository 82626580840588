import React, { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { HttpRequest } from '../../util/HttpRequest';
import styles from './RemoteContentPage.module.scss'
import ContainerWithOptionalHeader from '../ContainerWithOptionalHeader';
import HTMLRender from '../HTMLRender';
import ErrorModal from '../ErrorModal/ErrorModal';

interface Props {
    url: string;
    backupTitle: string;
}

function RemoteContentPage({ url, backupTitle }: Props) {

    const [content, setContent] = React.useState({
        title: '',
        html: ''
    })

    const [error, setError] = React.useState(false);

    useEffect(() => {
        HttpRequest.GET(url)
            .then(r => r.json())
            .then(r => {
                if (r.error) return setError(true);
                setContent({
                    title: r.data['attributes']['title'],
                    html: r.data['attributes']['content']
                })
            })
            .catch(() => {
                setError(true);
            });
    }, [url])

    return (
        <>
            <CssBaseline/>
            <ErrorModal open={ error } handleClose={ () => setError(false) }
                        title={ 'Uh oh, something went wrong' }
                        body={ `We couldn't process that request right now. Please wait a few minutes and try again` }
                        buttons={ [{ text: 'Ok', callback: () => setError(false) }] }/>
            <ContainerWithOptionalHeader title={ content.title || backupTitle } containerStyle={ { display: 'flex', paddingLeft: 1, paddingRight: 1, justifyContent: 'center' } }>
                <HTMLRender content={ content.html } style={ { overflow: 'overlay', maxWidth: '800px', padding: '0 20px' } } className={ styles.remoteContentContainer }/>
            </ContainerWithOptionalHeader>
        </>);
}

export default RemoteContentPage;
