import { Box, SxProps, Typography } from '@mui/material';
import * as React from 'react';

interface Props {
    title?: JSX.Element | string
    children: JSX.Element | never[]
    containerStyle?: SxProps,
}

export default function ContainerWithOptionalHeader({ title, children, containerStyle }: Props) {
    return <Box
        sx={ {
            width: '100%',
            left: 0,
            top: 0,
            position: 'absolute',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        } }
    >
        { title && <Typography textAlign={ 'center' } variant={ 'h6' } className={ 'headerBar' }>{ title }</Typography> }
        <Box sx={ { flex: 1, overflow: 'auto', ...containerStyle } }>
            { children }
        </Box>
    </Box>;
}
